import Vue from 'vue';
import debounce from 'lodash/debounce';
import types from '../../mutation-types';
import ConversationApi from '../../../api/inbox/conversation';
import MessageApi from '../../../api/inbox/message';
import { MESSAGE_STATUS, MESSAGE_TYPE } from 'shared/constants/messages';
import {
  createFileRequestActivityPendingMessage,
  createFileRequestPendingMessage,
  createPendingAICopilotMessage,
  createPendingAICopilotTextMessage,
  createPendingMessage,
} from 'dashboard/helper/commons';
import {
  buildConversationList,
  isOnMentionsView,
} from './helpers/actionHelpers';
import { REPLY_EDITOR_MODES } from 'components/widgets/WootWriter/constants';

const handleDeletedConversationErrorResponse = (
  commit,
  errorResponse,
  conversationId
) => {
  if (errorResponse?.response?.status === 404) {
    commit(types.DELETE_CONVERSATION, conversationId);
  } else {
    // eslint-disable-next-line no-console
    console.error(errorResponse);
  }
};

const markMessagesRead = async (commit, params, id) => {
  try {
    const {
      data: { agent_last_seen_at },
    } = await ConversationApi.markMessageRead(params);

    commit(types.MARK_MESSAGE_READ, {
      id,
      lastSeen: agent_last_seen_at,
    });
  } catch (error) {
    handleDeletedConversationErrorResponse(commit, error, id);
  }
};

const debouncedMarkMessageRead = debounce(markMessagesRead, 2000, {
  maxWait: 12000,
});

// actions
const actions = {
  getConversation: async ({ commit }, conversationId) => {
    try {
      const response = await ConversationApi.show(conversationId);
      commit(types.UPDATE_CONVERSATION, response.data);
      // Call commented for now as this payload won't include cognigy user profile, it comes from the API
      // commit(`contacts/${types.SET_CONTACT_ITEM}`, response.data.meta.sender);
    } catch (error) {
      handleDeletedConversationErrorResponse(commit, error, conversationId);
    }
  },

  fetchAllConversations: async ({ commit, dispatch }, params) => {
    commit(types.SET_LIST_LOADING_STATUS);
    try {
      const {
        data: { data },
      } = await ConversationApi.get(params);
      buildConversationList(
        { commit, dispatch },
        params,
        data,
        params.assigneeType
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  pollConversations: async ({ commit, dispatch, rootState }, params) => {
    try {
      const {
        data: { data },
      } = await ConversationApi.get({
        ...params,
        page: params.page ?? 1,
      });

      (data.payload ?? []).forEach(conversation => {
        commit(types.ADD_MESSAGE, {
          message: conversation.messages[conversation.messages.length - 1],
          rootState,
        });
      });
      commit(types.SET_ALL_CONVERSATION, data.payload);
      dispatch('conversationStats/set', data.meta);
      dispatch('conversationLabels/setBulkConversationLabels', data.payload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  pollFilteredConversations: async (
    { commit, dispatch, rootState },
    params
  ) => {
    try {
      const { data } = await ConversationApi.filter({
        ...params,
        page: params.page ?? 1,
      });

      (data.payload ?? []).forEach(conversation => {
        commit(types.ADD_MESSAGE, {
          message: conversation.messages[conversation.messages.length - 1],
          rootState,
        });
      });
      commit(types.SET_ALL_CONVERSATION, data.payload);
      dispatch('conversationStats/set', data.meta);
      dispatch('conversationLabels/setBulkConversationLabels', data.payload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  pollConversationsPage: async ({ commit, dispatch, rootState }, params) => {
    try {
      const {
        data: { data },
      } = await ConversationApi.get({ ...params });

      (data.payload ?? []).forEach(conversation => {
        commit(types.ADD_MESSAGE, {
          message: conversation.messages[conversation.messages.length - 1],
          rootState,
        });
      });
      commit(types.SET_ALL_CONVERSATION, data.payload);
      dispatch('conversationStats/set', data.meta);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  fetchFilteredConversations: async ({ commit, dispatch }, params) => {
    commit(types.SET_LIST_LOADING_STATUS);
    try {
      const { data } = await ConversationApi.filter(params);
      buildConversationList(
        { commit, dispatch },
        params,
        data,
        'appliedFilters'
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  emptyAllConversations({ commit }) {
    commit(types.EMPTY_ALL_CONVERSATION);
  },

  clearSelectedState({ commit }) {
    commit(types.CLEAR_CURRENT_CHAT_WINDOW);
  },

  fetchPreviousMessages: async ({ commit }, data) => {
    try {
      const {
        data: { meta, payload },
      } = await MessageApi.getPreviousMessages(data);
      commit(`conversationMetadata/${types.SET_CONVERSATION_METADATA}`, {
        id: data.conversationId,
        data: meta,
      });
      commit(types.SET_PREVIOUS_CONVERSATIONS, {
        id: data.conversationId,
        data: payload,
      });
      if (payload.length < 20) {
        commit(types.SET_ALL_MESSAGES_LOADED);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  fetchNextMessages: async ({ commit, dispatch, rootState }, data) => {
    try {
      const {
        data: { payload },
      } = await MessageApi.getNextMessages(data);

      payload.forEach(message => {
        commit(types.ADD_MESSAGE, {
          message,
          rootState,
        });
      });

      if (payload.length < 20) {
        commit(types.SET_ALL_MESSAGES_LOADED);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  async setActiveChat({ commit, dispatch }, data) {
    commit(types.SET_CURRENT_CHAT_WINDOW, data);
    commit(types.CLEAR_ALL_MESSAGES_LOADED);

    if (data.dataFetched === undefined) {
      try {
        await dispatch('fetchPreviousMessages', {
          conversationId: data.id,
          before: data.messages.length > 0 ? data.messages[0].id : null,
        });
        Vue.set(data, 'dataFetched', true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  },

  assignAgent: async ({ dispatch }, { conversationId, agentId }) => {
    Object.values(REPLY_EDITOR_MODES).forEach(mode => {
      dispatch(
        'draftStore/delete',
        {
          id: conversationId,
          replyType: mode,
        },
        { root: true }
      );
    });

    return ConversationApi.assignAgent({
      conversationId,
      agentId,
    }).then(response => {
      dispatch('setCurrentChatAssignee', response.data);
    });
  },

  setCurrentChatAssignee({ commit }, assignee) {
    commit(types.ASSIGN_AGENT, assignee);
  },

  assignTeam: async ({ dispatch }, { conversationId, teamId }) => {
    await ConversationApi.assignTeam({
      conversationId,
      teamId,
    }).then(response => {
      dispatch('setCurrentChatTeam', response.data);
    });
  },

  setCurrentChatTeam({ commit }, team) {
    commit(types.ASSIGN_TEAM, team);
  },

  toggleStatus: async (
    { commit },
    { conversationId, status, snoozedUntil = null, subStatus }
  ) => {
    try {
      const {
        data: {
          payload: {
            current_status: updatedStatus,
            snoozed_until: updatedSnoozedUntil,
            sub_status: updatedSubStatus,
          } = {},
        } = {},
      } = await ConversationApi.toggleStatus({
        conversationId,
        status,
        snoozedUntil,
        sub_status: subStatus,
      });
      commit(types.CHANGE_CONVERSATION_STATUS, {
        conversationId,
        status: updatedStatus,
        snoozedUntil: updatedSnoozedUntil,
        subStatus: updatedSubStatus,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  createPendingMessageAndSend: async ({ dispatch }, data) => {
    const pendingMessage = createPendingMessage(data);
    dispatch('sendMessageWithData', pendingMessage);
  },

  createPendingAICopilotMessageAndSend: async ({ dispatch }, data) => {
    const pendingMessage = createPendingAICopilotMessage(data);
    dispatch('sendMessageWithData', pendingMessage);
  },

  createPendingAICopilotTextMessageAndSend: async ({ dispatch }, data) => {
    const pendingMessage = createPendingAICopilotTextMessage(data);
    dispatch('sendMessageWithData', pendingMessage);
  },

  sendMessageWithData: async ({ commit, rootState }, pendingMessage) => {
    try {
      commit(types.ADD_MESSAGE, {
        message: {
          ...pendingMessage,
          status: MESSAGE_STATUS.PROGRESS,
        },
        rootState,
      });
      const response = await MessageApi.create(pendingMessage);
      commit(types.ADD_MESSAGE, {
        message: {
          ...response.data,
          status: MESSAGE_STATUS.SENT,
        },
        rootState,
      });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.error
        : undefined;
      commit(types.ADD_MESSAGE, {
        ...pendingMessage,
        meta: {
          error: errorMessage,
        },
        status: MESSAGE_STATUS.FAILED,
      });
      throw error;
    }
  },

  sendFileRequest: async ({ commit, rootState }, data) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const pendingMessage = createFileRequestPendingMessage(data);
      commit(types.ADD_MESSAGE, { message: pendingMessage, rootState });
      const response = await MessageApi.create(pendingMessage);
      commit(types.ADD_MESSAGE, {
        ...response.data,
        status: MESSAGE_STATUS.SENT,
      });
    } catch (error) {
      throw error;
    }
  },

  sendFileRequestActivity: async ({ commit, rootState }, data) => {
    // eslint-disable-next-line no-useless-catch
    try {
      // Send an activity message
      const pendingMessage = createFileRequestActivityPendingMessage(data);
      const response = await MessageApi.create(pendingMessage);
      commit(types.ADD_MESSAGE, {
        message: {
          ...response.data,
          status: MESSAGE_STATUS.SENT,
        },
        rootState,
      });
    } catch (error) {
      throw error;
    }
  },

  addMessage({ commit, state, rootState }, message) {
    if (state.appliedFilters && !state.appliedFilters.length) {
      commit(types.ADD_MESSAGE, { message, rootState });
    }
    if (message.message_type === MESSAGE_TYPE.INCOMING) {
      commit(types.SET_CONVERSATION_CAN_REPLY, {
        conversationId: message.conversation_id,
        canReply: true,
      });
    }
  },

  updateConversationRead({ commit }, timestamp) {
    commit(types.SET_CONVERSATION_LAST_SEEN, timestamp);
  },

  updateMessage({ commit, rootState }, message) {
    commit(types.ADD_MESSAGE, { message, rootState });
  },

  deleteMessage: async function deleteLabels(
    { commit },
    { conversationId, messageId }
  ) {
    try {
      const response = await MessageApi.delete(conversationId, messageId);
      const { data } = response;
      // The delete message is actually deleting the content.
      commit(types.DELETE_MESSAGE, data);
    } catch (error) {
      throw new Error(error);
    }
  },

  addConversation({ commit, state, dispatch, rootState }, conversation) {
    const { currentInbox, appliedFilters } = state;
    const {
      inbox_id: inboxId,
      meta: { sender },
    } = conversation;
    const hasAppliedFilters = !!appliedFilters.length;
    const isMatchingInboxFilter =
      !currentInbox || Number(currentInbox) === inboxId;
    if (
      !hasAppliedFilters &&
      !isOnMentionsView(rootState) &&
      isMatchingInboxFilter
    ) {
      commit(types.ADD_CONVERSATION, conversation);
      commit(`contactConversations/${types.ADD_CONTACT_CONVERSATION}`, {
        id: sender.id,
        data: conversation,
      });
      dispatch('contacts/setContact', sender);
    }
  },

  addMentions({ dispatch, rootState }, conversation) {
    if (isOnMentionsView(rootState)) {
      dispatch('updateConversation', conversation);
    }
  },

  updateConversation({ commit, dispatch, rootState }, conversation) {
    const {
      meta: { sender },
    } = conversation;
    commit(types.UPDATE_CONVERSATION, conversation);

    if (conversation.messages.length) {
      commit(types.ADD_MESSAGE, {
        message: conversation.messages[conversation.messages.length - 1],
        rootState,
      });
    }

    dispatch('conversationPage/reset');
    dispatch('contacts/setContact', sender);
  },

  markMessagesRead: async ({ commit, state }, params) => {
    if (state.isFetching) {
      return;
    }

    commit('setFetching', true);

    try {
      const id = params.id;

      await debouncedMarkMessageRead(commit, params, id);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      commit('setFetching', false);
    }
  },

  updateConversationLastActivity(
    { commit },
    { conversationId, lastActivityAt }
  ) {
    commit(types.UPDATE_CONVERSATION_LAST_ACTIVITY, {
      lastActivityAt,
      conversationId,
    });
  },

  setChatStatusFilter({ commit }, data) {
    commit(types.CHANGE_CHAT_STATUS_FILTER, data);
  },

  setChatSortFilter({ commit }, data) {
    commit(types.CHANGE_CHAT_SORT_FILTER, data);
  },

  updateAssignee({ commit }, data) {
    commit(types.UPDATE_ASSIGNEE, data);
  },

  updateConversationContact({ commit }, data) {
    if (data.id) {
      commit(`contacts/${types.SET_CONTACT_ITEM}`, data);
    }
    commit(types.UPDATE_CONVERSATION_CONTACT, data);
  },

  setActiveInbox({ commit }, inboxId) {
    commit(types.SET_ACTIVE_INBOX, inboxId);
  },

  muteConversation: async ({ commit }, conversationId) => {
    try {
      await ConversationApi.mute(conversationId);
      commit(types.MUTE_CONVERSATION);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  unmuteConversation: async ({ commit }, conversationId) => {
    try {
      await ConversationApi.unmute(conversationId);
      commit(types.UNMUTE_CONVERSATION);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  clearConversationChatHistory: async ({ commit }, conversationId) => {
    try {
      await ConversationApi.clearChatHistory(conversationId);
      commit(types.CLEAR_CURRENT_CHAT_HISTORY);
    } catch (error) {
      throw new Error(error);
    }
  },

  sendTranscriptToEmail: async (_, { conversationId, email }) => {
    try {
      await ConversationApi.sendTranscriptToEmail({
        conversationId,
        email,
      });
    } catch (error) {
      throw new Error(error);
    }
  },

  sendTranscriptToAssigneeEmail: async (_, { conversationId }) => {
    try {
      await ConversationApi.sendTranscriptToAssigneeEmail({
        conversationId,
      });
    } catch (error) {
      throw new Error(error);
    }
  },

  updateCustomAttributes: async (
    { commit },
    { conversationId, customAttributes }
  ) => {
    try {
      const response = await ConversationApi.updateCustomAttributes({
        conversationId,
        customAttributes,
      });
      const { custom_attributes } = response.data;
      commit(types.UPDATE_CONVERSATION_CUSTOM_ATTRIBUTES, custom_attributes);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  setConversationFilters({ commit }, data) {
    commit(types.SET_CONVERSATION_FILTERS, data);
  },

  clearConversationFilters({ commit }) {
    commit(types.CLEAR_CONVERSATION_FILTERS);
  },

  assignPriority: async ({ dispatch }, { conversationId, priority }) => {
    try {
      await ConversationApi.togglePriority({
        conversationId,
        priority,
      });

      dispatch('setCurrentChatPriority', priority);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  setCurrentChatPriority({ commit }, priority) {
    commit(types.ASSIGN_PRIORITY, priority);
  },

  changeInbox: async ({ commit, dispatch }, { conversationId, inboxId }) => {
    try {
      await ConversationApi.toggleInbox({
        conversationId,
        inboxId,
      });

      commit(types.CHANGE_INBOX, inboxId);
      dispatch('conversationPage/reset');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  updateInboxOnConversations(
    { commit, dispatch, rootState },
    { conversationIds, inboxId }
  ) {
    conversationIds.forEach(conversationId => {
      commit(types.UPDATE_CONVERSATION_INBOX, {
        conversationId,
        inboxId,
      });
    });

    dispatch('conversationPage/reset');

    if (
      rootState.inboxes.records.findIndex(inbox => inbox.id === inboxId) === -1
    ) {
      dispatch('clearSelectedState');
    }
  },
};

export default actions;
