<template>
  <div class="contact--profile">
    <div class="contact--info">
      <WootThumbnail
        v-if="showAvatar"
        :src="contact.thumbnail"
        size="56px"
        :username="contact.name"
        :status="contact.availability_status"
      />

      <div class="contact--details">
        <div v-if="showAvatar" class="contact--name-wrap">
          <h3
            class="sub-block-title contact--name"
            data-test-id="contact_info_name"
          >
            {{ contact.name }}
          </h3>
        </div>
        <div class="contact--metadata">
          <contact-info-row
            v-if="contact.email"
            :href="contact.email ? `mailto:${contact.email}` : ''"
            :value="contact.email"
            icon="mail"
            emoji="✉️"
            :title="$t('CONTACT_PANEL.EMAIL_ADDRESS')"
            show-copy
          />
          <div v-for="(profileValue, profileKey) in profile" :key="profileKey">
            <div class="cognigy-profile">
              <span
                class="key-field"
                :data-test-id="`contact_info_key_field_${profileKey}`"
                >{{
                  typeof profileValue === 'object' && profileValue.field
                    ? profileValue.field
                    : $t(mapProfileKeyToHumanString(profileKey, profileValue))
                }}</span
              >
              <span
                class="value-field"
                :data-test-id="`contact_info_${profileKey}`"
                >{{ filterCognigyProfileValue(profileKey, profileValue) }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="contact-actions">
        <woot-button
          v-if="!isProfileEmpty"
          v-tooltip="$t('EDIT_CONTACT.BUTTON_LABEL')"
          title="$t('EDIT_CONTACT.BUTTON_LABEL')"
          class="edit-contact"
          icon="edit"
          variant="smooth"
          size="small"
          data-test-id="contact_info_edit_button"
          @click="toggleEditModal"
        />
        <woot-button
          v-if="!isProfileEmpty"
          v-tooltip="
            $t(
              showEmptyProperties
                ? 'TOGGLE_EMPTY_PROPERTIES.BUTTON_LABEL_HIDE'
                : 'TOGGLE_EMPTY_PROPERTIES.BUTTON_LABEL_SHOW'
            )
          "
          title="$t('EDIT_CONTACT.BUTTON_LABEL')"
          :icon="showEmptyProperties ? 'eye-circle' : 'eye-off-circle'"
          variant="smooth"
          size="small"
          data-test-id="contact_info_toggle_button"
          @click="toggleShowEmptyProperties"
        />
      </div>
      <edit-contact
        v-if="showEditModal"
        :show="showEditModal"
        :contact="contact"
        :project-id="projectId"
        @cancel="toggleEditModal"
      />
    </div>
  </div>
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';

import ContactInfoRow from './ContactInfoRow.vue';
import EditContact from './EditContact.vue';
import mapProfileKeyToHumanString from 'shared/helpers/mapProfileKeyToHumanString';
import filterCognigyProfileValue from 'shared/helpers/filterCognigyProfileValue';
import alertMixin from 'shared/mixins/alertMixin';
import adminMixin from '../../../../mixins/isAdmin';
import { isEmptyObject, isObjectWithEmptyKeys } from 'dashboard/helper/commons';
import wootConstants from '../../../../constants';
import { mapGetters } from 'vuex';
import WootUIKit from 'components';

export default {
  components: {
    WootThumbnail: WootUIKit.Thumbnail,
    WootButton: WootUIKit.Button,
    ContactInfoRow,
    EditContact,
  },
  mixins: [alertMixin, adminMixin, clickaway],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    channelType: {
      type: String,
      default: '',
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
    projectId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showEditModal: false,
      showConversationModal: false,
      showMergeModal: false,
      showDeleteModal: false,
      showEmptyProperties: false,
    };
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      uiFlags: 'contacts/getUIFlags',
    }),
    additionalAttributes() {
      // return this.contact.additional_attributes || {};
      return {};
    },
    profile() {
      const hiddenKeys = ['firstname', 'lastname', 'profilepic'];

      const isEmpty = value => {
        if (Array.isArray(value)) {
          return value.length === 0;
        }
        // eslint-disable-next-line dot-notation
        if (value['value'] === '') {
          return true;
        }
        if (typeof value === 'object' && value !== null) {
          return this.isEmptyProfileEntry(value);
        }
        if (typeof value === 'boolean') {
          return false; // Booleans are never considered "empty"
        }

        return (
          value === '' || value === null || value === undefined || value === 0
        );
      };

      return Object.keys(this.contact?.profile || {}).reduce((acc, key) => {
        const value = this.contact?.profile[key];

        // Skip hidden keys and empty values
        if (
          hiddenKeys.includes(key) ||
          (!this.showEmptyProperties && isEmpty(value))
        ) {
          return acc;
        }

        acc[key] = value;
        return acc;
      }, {});
    },
    isProfileEmpty() {
      return isEmptyObject(this.profile);
    },

    // Delete Modal
    confirmDeleteMessage() {
      return `${this.$t('DELETE_CONTACT.CONFIRM.MESSAGE')} ${
        this.contact.name
      } ?`;
    },
  },
  methods: {
    filterCognigyProfileValue,
    mapProfileKeyToHumanString,
    toggleMergeModal() {
      this.showMergeModal = !this.showMergeModal;
    },
    toggleEditModal() {
      this.showEditModal = !this.showEditModal;
    },
    toggleConversationModal() {
      this.showConversationModal = !this.showConversationModal;
    },
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    confirmDeletion() {
      this.deleteContact(this.contact);
      this.closeDelete();
    },
    closeDelete() {
      this.showDeleteModal = false;
      this.showConversationModal = false;
      this.showEditModal = false;
    },
    // findCountryFlag(countryCode, cityAndCountry) {
    //   try {
    //     const countryFlag = countryCode ? flag(countryCode) : '🌎';
    //     return `${cityAndCountry} ${countryFlag}`;
    //   } catch (error) {
    //     return '';
    //   }
    // },
    async deleteContact({ id }) {
      try {
        await this.$store.dispatch('contacts/delete', id);
        this.$emit('panel-close');
        this.showAlert(this.$t('DELETE_CONTACT.API.SUCCESS_MESSAGE'), {
          status: wootConstants.SNACKBAR_TYPE.SUCCESS,
        });
        if (this.$route.name !== 'contacts_dashboard') {
          this.$router.push({ name: 'contacts_dashboard' });
        }
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('DELETE_CONTACT.API.ERROR_MESSAGE'),
          {
            status: wootConstants.SNACKBAR_TYPE.ERROR,
          }
        );
      }
    },
    openMergeModal() {
      this.toggleMergeModal();
    },
    isEmptyProfileEntry(value) {
      return isEmptyObject(value) || isObjectWithEmptyKeys(value);
    },
    toggleShowEmptyProperties() {
      this.showEmptyProperties = !this.showEmptyProperties;
    },
  },
};
</script>

<style scoped lang="scss">
.contact--profile {
  position: relative;
  align-items: flex-start;
  padding: var(--space-normal);

  .user-thumbnail-box {
    margin-right: var(--space-normal);
  }
}

.contact--details {
  margin-top: var(--space-small);
  width: 100%;
}

.contact--info {
  text-align: left;
}

.contact--name-wrap {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-small);
}

.contact--name {
  text-transform: capitalize;
  white-space: normal;
  margin: 0 var(--space-smaller) 0 0;

  a {
    color: var(--color-body);
  }
}

.contact--metadata {
  margin-bottom: var(--space-slab);
}

.contact-actions {
  margin-top: var(--space-small);
}

.contact-actions {
  display: flex;
  align-items: center;
  width: 100%;

  .new-message,
  .edit-contact,
  .merge-contact,
  .delete-contact {
    margin-right: var(--space-small);
  }
}
.merege-summary--card {
  padding: var(--space-normal);
}
.contact--bio {
  word-wrap: break-word;
}

.button--contact-menu {
  position: absolute;
  right: var(--space-normal);
  top: 0;
}

.cognigy-profile {
  margin-bottom: var(--space-small);

  .key-field {
    color: var(--b-500);
    margin-right: var(--space-small);
  }

  .value-field {
    color: var(--b-900);
    font-weight: var(--font-weight-medium);
  }
}
</style>
