<template>
  <div v-if="isConversationsPanelOpen" class="conversations-list-wrap">
    <slot />
    <div
      class="chat-list__top"
      :class="{ filter__applied: hasAppliedFiltersOrActiveFolders }"
    >
      <h1 class="page-title text-truncate" :title="pageTitle">
        {{ pageTitle }}
      </h1>

      <div class="filter--actions">
        <div v-if="hasAppliedFilters && !hasActiveFolders">
          <woot-button
            v-tooltip.top-end="$t('FILTER.CUSTOM_VIEWS.ADD.SAVE_BUTTON')"
            size="tiny"
            variant="smooth"
            color-scheme="secondary"
            icon="save"
            @click="onClickOpenAddFoldersModal"
          />
          <woot-button
            v-tooltip.top-end="$t('FILTER.CLEAR_BUTTON_LABEL')"
            size="tiny"
            variant="smooth"
            color-scheme="alert"
            icon="dismiss-circle"
            @click="clearFilter"
          />
        </div>
        <div v-if="hasActiveFolders">
          <woot-button
            v-tooltip.top-end="$t('FILTER.CUSTOM_VIEWS.DELETE.DELETE_BUTTON')"
            size="tiny"
            variant="smooth"
            color-scheme="alert"
            icon="delete"
            class="delete-custom-view__button"
            @click="onClickOpenDeleteFoldersModal"
          />
        </div>

        <woot-button
          v-else
          v-tooltip.top-end="$t('FILTER.TOOLTIP_LABEL')"
          variant="clear"
          color-scheme="secondary"
          icon="filter"
          size="small"
          class="btn-filter"
          @click="onToggleAdvanceFiltersModal"
        />

        <conversation-basic-filter
          v-if="!hasAppliedFiltersOrActiveFolders"
          @changeFilter="onBasicFilterChange"
        />
      </div>
    </div>

    <add-custom-views
      v-if="showAddFoldersModal"
      :custom-views-query="foldersQuery"
      :open-last-saved-item="openLastSavedItemInFolder"
      @close="onCloseAddFoldersModal"
    />

    <delete-custom-views
      v-if="showDeleteFoldersModal"
      :show-delete-popup.sync="showDeleteFoldersModal"
      :active-custom-view="activeFolder"
      :custom-views-id="foldersId"
      :open-last-item-after-delete="openLastItemAfterDeleteInFolder"
      @close="onCloseDeleteFoldersModal"
    />

    <chat-type-tabs
      v-if="!hasAppliedFiltersOrActiveFolders"
      :items="assigneeTabItems"
      :active-tab="activeAssigneeTab"
      class="tab--chat-type"
      data-test-id="chat_type_tabs"
      @chatTabChange="updateAssigneeTab"
    />

    <p v-if="!chatListLoading && !conversationList.length" class="content-box">
      {{ $t('CHAT_LIST.LIST.404') }}
    </p>
    <conversation-bulk-actions
      v-if="selectedConversations.length"
      :conversations="selectedConversations"
      :all-conversations-selected="allConversationsSelected"
      :selected-inboxes="uniqueInboxes"
      :available-inboxes="inboxes"
      :show-open-action="allSelectedConversationsStatus('open')"
      :show-resolved-action="allSelectedConversationsStatus('resolved')"
      :show-snoozed-action="allSelectedConversationsStatus('snoozed')"
      :contains-abandoned-conversation="conversationsContainStatus('abandoned')"
      :contains-resolved-conversation="conversationsContainStatus('resolved')"
      @select-all-conversations="selectAllConversations"
      @assign-agent="onAssignAgent"
      @update-conversations="onUpdateConversations"
      @assign-labels="onAssignLabels"
      @assign-inbox="onAssignInbox"
    />
    <div ref="activeConversation" class="conversations-list">
      <conversation-card
        v-for="chat in conversationList"
        :key="chat.id"
        :active-label="label"
        :team-id="teamId"
        :folders-id="foldersId"
        :chat="chat"
        :conversation-type="conversationType"
        :show-assignee="showAssigneeInConversationCard"
        :data-test-id="'chat_' + chat.id"
        :selected="isConversationSelected(chat.id)"
        @select-conversation="selectConversation"
        @de-select-conversation="deSelectConversation"
      />

      <div v-if="chatListLoading" class="text-center">
        <span class="spinner" />
      </div>

      <woot-button
        v-if="!hasCurrentPageEndReached && !chatListLoading"
        variant="clear"
        size="expanded"
        @click="loadMoreConversations"
      >
        {{ $t('CHAT_LIST.LOAD_MORE_CONVERSATIONS') }}
      </woot-button>

      <p
        v-if="showEndOfListMessage"
        class="text-center text-muted end-of-list-text"
      >
        {{ $t('CHAT_LIST.EOF') }}
      </p>
    </div>
    <woot-modal
      :show.sync="showAdvancedFilters"
      :on-close="onToggleAdvanceFiltersModal"
      size="medium"
    >
      <conversation-advanced-filter
        v-if="showAdvancedFilters"
        :initial-filter-types="advancedFilterTypes"
        :on-close="onToggleAdvanceFiltersModal"
        :conversation-inbox="conversationInbox"
        @applyFilter="onApplyFilter"
      />
    </woot-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { newMessageNotification } from 'shared/helpers/AudioNotificationHelper';
import { MESSAGE_TYPE } from 'shared/constants/messages';

import ConversationAdvancedFilter from './widgets/conversation/ConversationAdvancedFilter.vue';
import ChatTypeTabs from './widgets/ChatTypeTabs.vue';
import ConversationCard from './widgets/conversation/ConversationCard.vue';
import timeMixin from '../mixins/time';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import conversationMixin from '../mixins/conversations';
import uiSettingsMixin from '../mixins/uiSettings';
import wootConstants from '../constants';
import advancedFilterTypes from './widgets/conversation/advancedFilterItems';
import filterQueryGenerator from '../helper/filterQueryGenerator.js';
import AddCustomViews from 'dashboard/routes/dashboard/customviews/AddCustomViews.vue';
import DeleteCustomViews from 'dashboard/routes/dashboard/customviews/DeleteCustomViews.vue';
import ConversationBulkActions from './widgets/conversation/conversationBulkActions/Index.vue';
import ConversationBasicFilter from './widgets/conversation/ConversationBasicFilter.vue';
import alertMixin from 'shared/mixins/alertMixin';

import {
  hasPressedAltAndJKey,
  hasPressedAltAndKKey,
} from 'shared/helpers/KeyboardHelpers';
import { REPLY_EDITOR_MODES } from 'components/widgets/WootWriter/constants';
import WootButton from 'components/ui/WootButton.vue';
import WootModal from 'components/Modal.vue';

export default {
  components: {
    WootModal,
    AddCustomViews,
    ChatTypeTabs,
    ConversationCard,
    ConversationAdvancedFilter,
    DeleteCustomViews,
    ConversationBulkActions,
    ConversationBasicFilter,
    WootButton,
  },
  mixins: [
    timeMixin,
    conversationMixin,
    eventListenerMixins,
    alertMixin,
    uiSettingsMixin,
  ],
  props: {
    conversationInbox: {
      type: [String, Number],
      default: 0,
    },
    teamId: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    conversationType: {
      type: String,
      default: '',
    },
    foldersId: {
      type: [String, Number],
      default: 0,
    },
    filterParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeAssigneeTab: wootConstants.ASSIGNEE_TYPE.ME,
      activeStatus: wootConstants.STATUS_TYPE.OPEN,
      activeSortBy: wootConstants.SORT_BY_TYPE.LATEST,
      showAdvancedFilters: false,
      advancedFilterTypes: advancedFilterTypes.map(filter => ({
        ...filter,
        attributeName: this.$t(`FILTER.ATTRIBUTES.${filter.attributeI18nKey}`),
      })),
      foldersQuery: {},
      showAddFoldersModal: false,
      showDeleteFoldersModal: false,
      selectedConversations: [],
      selectedInboxes: [],
      showAllConversations: true,
      intervalRef: null,
      paginationIntervals: [],
      trackedConversations: [],
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      chatLists: 'getAllConversations',
      mineChatsList: 'getMineChats',
      allChatList: 'getAllStatusChats',
      unAssignedChatsList: 'getUnAssignedChats',
      chatListLoading: 'getChatListLoadingStatus',
      currentUserID: 'getCurrentUserID',
      currentAccountId: 'getCurrentAccountId',
      currentRoles: 'getCurrentRoles',
      activeInbox: 'getSelectedInbox',
      conversationStats: 'conversationStats/getStats',
      appliedFilters: 'getAppliedConversationFilters',
      folders: 'customViews/getCustomViews',
      inboxes: 'inboxes/getInboxes',
      agents: 'agents/getAgents',
      isConversationsPanelOpen: 'ui/isConversationsPanelOpen',
    }),
    hasAppliedFilters() {
      return this.appliedFilters.length !== 0;
    },
    isAdministrator() {
      return this.currentRoles.includes('administrator');
    },
    isAgent() {
      return (
        this.currentRoles.includes('agent') &&
        !this.currentRoles.includes('administrator') &&
        !this.currentRoles.includes('supervisor')
      );
    },
    isInboxRoute() {
      return this.activeInbox;
    },
    isMentionsRoute() {
      return (
        this.$route.name === 'conversation_mentions' ||
        this.$route.name === 'conversation_through_mentions'
      );
    },
    hasActiveFolders() {
      return this.activeFolder && this.foldersId !== 0;
    },
    hasAppliedFiltersOrActiveFolders() {
      return this.hasAppliedFilters || this.hasActiveFolders;
    },
    savedFoldersValue() {
      if (this.hasActiveFolders) {
        const payload = this.activeFolder.query;
        this.fetchSavedFilteredConversations(payload);
      }
      return {};
    },
    assigneeTabItems() {
      const ASSIGNEE_TYPE_TAB_KEYS = {
        me: 'mineCount',
        unassigned: 'unAssignedCount',
        all: 'allCount',
      };

      return Object.keys(ASSIGNEE_TYPE_TAB_KEYS)
        .map(key => {
          const count =
            this.conversationStats[ASSIGNEE_TYPE_TAB_KEYS[key]] || 0;
          const tab = {
            key,
            name: this.$t(`CHAT_LIST.ASSIGNEE_TYPE_TABS.${key}`),
            count,
          };

          if (this.isInboxRoute) {
            // Show the 'all' tab in Inboxes only if the inbox allows it or you are an admin
            return this.showInboxAllTab(tab, key);
          }

          if (this.isMentionsRoute) {
            // Show the 'all' tab in the mentions route, regardless of inbox configuration
            return tab;
          }
          // Show the 'all' tab in conversations only if you are an admin
          return this.showConversationsAllTab(tab, key);
        })
        .filter(tab => tab);
    },
    showAssigneeInConversationCard() {
      return (
        this.hasAppliedFiltersOrActiveFolders ||
        this.activeAssigneeTab === wootConstants.ASSIGNEE_TYPE.ALL
      );
    },
    showEndOfListMessage() {
      return (
        this.conversationList.length &&
        this.hasCurrentPageEndReached &&
        !this.chatListLoading
      );
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.activeInbox);
    },
    currentPage() {
      return this.$store.getters['conversationPage/getCurrentPageFilter'](
        this.activeAssigneeTab
      );
    },
    currentPageFilterKey() {
      return this.hasAppliedFiltersOrActiveFolders
        ? 'appliedFilters'
        : this.activeAssigneeTab;
    },
    currentFiltersPage() {
      return this.$store.getters['conversationPage/getCurrentPageFilter'](
        this.currentPageFilterKey
      );
    },
    hasCurrentPageEndReached() {
      return this.$store.getters['conversationPage/getHasEndReached'](
        this.currentPageFilterKey
      );
    },
    conversationFilters() {
      return {
        inboxId: this.conversationInbox ? this.conversationInbox : undefined,
        assigneeType: this.activeAssigneeTab,
        status: this.activeStatus,
        sortBy: this.activeSortBy,
        page: this.currentPage + 1,
        labels: this.label ? [this.label] : undefined,
        teamId: this.teamId ? this.teamId : undefined,
        conversationType: this.conversationType
          ? this.conversationType
          : undefined,
        folders: this.hasActiveFolders ? this.savedFoldersValue : undefined,
      };
    },
    pageTitle() {
      if (this.inbox.name) {
        return this.inbox.name;
      }
      if (this.activeTeam.name) {
        return this.activeTeam.name;
      }
      if (this.label) {
        return `#${this.label}`;
      }
      if (this.conversationType === 'mention') {
        return this.$t('CHAT_LIST.MENTION_HEADING');
      }
      if (this.hasActiveFolders) {
        return this.activeFolder.name;
      }
      return this.$t('CHAT_LIST.TAB_HEADING');
    },
    conversationList() {
      let conversationList = [];
      if (!this.hasAppliedFiltersOrActiveFolders) {
        const filters = this.conversationFilters;
        if (this.activeAssigneeTab === 'me') {
          conversationList = [...this.mineChatsList(filters)];
        } else if (this.activeAssigneeTab === 'unassigned') {
          conversationList = [...this.unAssignedChatsList(filters)];
        } else {
          conversationList = [...this.allChatList(filters)];
        }
      } else {
        conversationList = [...this.chatLists];
      }

      return conversationList;
    },
    activeFolder() {
      if (this.foldersId) {
        const activeView = this.folders.filter(
          view => view.id === Number(this.foldersId)
        );
        const [firstValue] = activeView;
        return firstValue;
      }
      return undefined;
    },
    activeTeam() {
      if (this.teamId) {
        return this.$store.getters['teams/getTeam'](this.teamId);
      }
      return {};
    },
    allConversationsSelected() {
      return (
        this.conversationList.length === this.selectedConversations.length &&
        this.conversationList.every(el =>
          this.selectedConversations.includes(el.id)
        )
      );
    },
    uniqueInboxes() {
      return [...new Set(this.selectedInboxes)];
    },
    inboxFilter() {
      return {
        attribute_key: 'inbox_id',
        filter_operator: 'equal_to',
        query_operator: 'and',
        attribute_model: 'standard',
        values: [
          this.inboxes.find(
            inbox => inbox.id.toString() === this.conversationInbox
          ),
        ],
      };
    },
  },
  watch: {
    activeTeam() {
      this.resetAndFetchData();
    },
    conversationInbox() {
      this.resetAndFetchData();
    },
    label() {
      this.resetAndFetchData();
    },
    conversationType() {
      this.resetAndFetchData();
    },
    inbox() {
      this.showAllConversations = this.inbox.conversations_show_all;
      if (
        this.activeAssigneeTab === 'all' &&
        !this.inbox.conversations_show_all
      ) {
        this.activeAssigneeTab = 'me';
      }
    },
    activeFolder() {
      if (!this.hasAppliedFilters) {
        this.resetAndFetchData();
      }
    },
    currentPage() {
      if (!this.enableConversationPolling) {
        return;
      }

      const page = this.currentPage;

      if (page <= 1) {
        this.clearPaginationIntervals();
        return;
      }

      const intervalId = setInterval(() => {
        this.$store.dispatch('pollConversationsPage', {
          ...this.conversationFilters,
          page,
        });
      }, this.pollingInterval.paginatedConversations);

      this.paginationIntervals.push(intervalId);
    },
  },
  async mounted() {
    await this.$store.dispatch('setChatStatusFilter', this.activeStatus);
    await this.$store.dispatch('setChatSortFilter', this.activeSortBy);

    if (this.enableConversationPolling) {
      this.intervalRef = setInterval(async () => {
        if (this.appliedFilters.length > 0) {
          await this.$store.dispatch('pollFilteredConversations', {
            queryData: filterQueryGenerator(this.appliedFilters),
          });
        } else {
          await this.$store.dispatch('pollConversations', {
            ...this.conversationFilters,
            // first page needs to be fetched always
            page: 1,
          });
        }
      }, this.pollingInterval.latestConversations);
    }

    if (this.isAdministrator) {
      this.$store.dispatch('agents/get', { minified: true });
    }

    if (this.shouldFiltersBeLoaded()) {
      this.loadFilters();
    } else {
      this.resetAndFetchData();
    }

    bus.$on('fetch_conversation_stats', () => {
      this.$store.dispatch('conversationStats/get', this.conversationFilters);
    });

    if (this.enableNotificationPolling) {
      this.fillTrackedConversations();
      this.triggerAudioNotifications();

      this.fetchTabNotificationsInterval = setInterval(
        this.triggerAudioNotifications,
        this.pollingInterval.unreadNotifications
      );
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalRef);

    this.clearPaginationIntervals();

    if (this.fetchNotificationsInterval) {
      clearInterval(this.fetchNotificationsInterval);
    }
  },
  methods: {
    shouldFiltersBeLoaded() {
      if (this.filterParams !== undefined) {
        const params = Object.values(this.filterParams);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < params.length; i++) {
          if (params[i] !== undefined) {
            return true;
          }
        }
      }

      return false;
    },
    loadFilters() {
      const appliedFilters = [];
      if (this.filterParams?.status) {
        appliedFilters.push({
          attribute_key: 'status',
          attribute_model: 'standard',
          custom_attribute_type: '',
          filter_operator: 'equal_to',
          query_operator: 'and',
          values: [
            {
              id: this.filterParams?.status,
              name: this.$t(
                `CHAT_LIST.CHAT_STATUS_FILTER_ITEMS.${this.filterParams.status}.TEXT`
              ),
            },
          ],
        });
      }

      if (this.filterParams?.assigneeId) {
        const assigneeId = this.filterParams.assigneeId;
        const assigneeName = this.agents.find(
          agent => agent.id === assigneeId
        ).name;
        appliedFilters.push({
          attribute_key: 'assignee_id',
          attribute_model: 'standard',
          custom_attribute_type: '',
          filter_operator: 'equal_to',
          query_operator: 'and',
          values: [{ id: assigneeId, name: assigneeName }],
        });
      }

      if (this.filterParams?.notPresent) {
        this.filterParams.notPresent.forEach(key => {
          appliedFilters.push({
            attribute_key: key,
            attribute_model: 'standard',
            custom_attribute_type: '',
            filter_operator: 'is_not_present',
            query_operator: 'and',
            values: '',
          });
        });
      }
      if (appliedFilters.length > 0) {
        this.$store.dispatch('setConversationFilters', appliedFilters);

        this.onApplyFilter(appliedFilters);
      }
    },
    onApplyFilter(payload) {
      // In case we are inside an inbox, we add this inbox as a filter implicitly
      // We clone the payload to avoid reactive behavior and adding the new filter in the filter window
      // If there are no inbox, we just give the payload as it is
      const clonedPayload = [...payload];

      if (this.conversationInbox) {
        clonedPayload.push(this.inboxFilter);
      }
      this.foldersQuery = filterQueryGenerator(clonedPayload);
      this.$store.dispatch('conversationPage/reset');
      this.$store.dispatch('emptyAllConversations');
      this.fetchFilteredConversations(clonedPayload);
    },
    onClickOpenAddFoldersModal() {
      this.showAddFoldersModal = true;
    },
    onCloseAddFoldersModal() {
      this.showAddFoldersModal = false;
    },
    onClickOpenDeleteFoldersModal() {
      this.showDeleteFoldersModal = true;
    },
    onCloseDeleteFoldersModal() {
      this.showDeleteFoldersModal = false;
    },
    onToggleAdvanceFiltersModal() {
      this.showAdvancedFilters = !this.showAdvancedFilters;
    },
    showConversationsAllTab(tab, key) {
      if (key === 'all') {
        return this.isAgent ? null : tab;
      }
      return tab;
    },
    showInboxAllTab(tab, key) {
      if (key === 'all' && this.isAgent) {
        return this.showAllConversations ? tab : null;
      }
      return tab;
    },
    getKeyboardListenerParams() {
      const allConversations = this.$refs.activeConversation.querySelectorAll(
        'div.conversations-list div.conversation'
      );
      const activeConversation = this.$refs.activeConversation.querySelector(
        'div.conversations-list div.conversation.active'
      );
      const activeConversationIndex = [...allConversations].indexOf(
        activeConversation
      );
      const lastConversationIndex = allConversations.length - 1;
      return {
        allConversations,
        activeConversation,
        activeConversationIndex,
        lastConversationIndex,
      };
    },
    handleKeyEvents(e) {
      if (hasPressedAltAndJKey(e)) {
        const { allConversations, activeConversationIndex } =
          this.getKeyboardListenerParams();
        if (activeConversationIndex === -1) {
          allConversations[0].click();
        }
        if (activeConversationIndex >= 1) {
          allConversations[activeConversationIndex - 1].click();
        }
      }
      if (hasPressedAltAndKKey(e)) {
        const {
          allConversations,
          activeConversationIndex,
          lastConversationIndex,
        } = this.getKeyboardListenerParams();
        if (activeConversationIndex === -1) {
          allConversations[lastConversationIndex].click();
        } else if (activeConversationIndex < lastConversationIndex) {
          allConversations[activeConversationIndex + 1].click();
        }
      }
    },
    clearFilter() {
      this.resetAndFetchData();
    },
    resetAndFetchData() {
      this.$store.dispatch('conversationPage/reset');
      this.$store.dispatch('emptyAllConversations');
      this.$store.dispatch('clearConversationFilters');
      if (this.hasActiveFolders) {
        const payload = this.activeFolder.query;
        this.fetchSavedFilteredConversations(payload);
      }
      if (this.foldersId) {
        return;
      }
      this.fetchConversations();
    },
    fetchConversations() {
      this.$store
        .dispatch('fetchAllConversations', this.conversationFilters)
        .then(() => {
          this.$emit('conversation-load');
          this.fillTrackedConversations();
        });
    },
    updateInboxOnConversations(conversationIds, inboxId) {
      this.$store.dispatch('updateInboxOnConversations', {
        conversationIds,
        inboxId,
      });
    },
    loadMoreConversations() {
      if (!this.hasAppliedFiltersOrActiveFolders) {
        this.fetchConversations();
      }
      if (this.hasActiveFolders) {
        const payload = this.activeFolder.query;
        this.fetchSavedFilteredConversations(payload);
      }
      if (this.hasAppliedFilters) {
        if (this.conversationInbox) {
          const clonedAppliedFilters = [...this.appliedFilters];
          clonedAppliedFilters.push(this.inboxFilter);
          this.fetchFilteredConversations(clonedAppliedFilters);
        } else {
          this.fetchFilteredConversations(this.appliedFilters);
        }
      }
    },
    fetchFilteredConversations(payload) {
      let page = this.currentFiltersPage + 1;
      this.$store
        .dispatch('fetchFilteredConversations', {
          queryData: filterQueryGenerator(payload),
          page,
        })
        .then(() => this.$emit('conversation-load'));
      this.showAdvancedFilters = false;
    },
    fetchSavedFilteredConversations(payload) {
      let page = this.currentFiltersPage + 1;
      this.$store
        .dispatch('fetchFilteredConversations', {
          queryData: payload,
          page,
        })
        .then(() => this.$emit('conversation-load'));
    },
    updateAssigneeTab(selectedTab) {
      if (this.activeAssigneeTab !== selectedTab) {
        this.resetBulkActions();
        bus.$emit('clearSearchInput');
        this.activeAssigneeTab = selectedTab;
        if (!this.currentPage) {
          this.fetchConversations();
        }
      }
    },
    resetBulkActions() {
      this.selectedConversations = [];
      this.selectedInboxes = [];
    },
    onBasicFilterChange(value, type) {
      if (type === 'status') {
        this.activeStatus = value;
      } else {
        this.activeSortBy = value;
      }
      this.resetAndFetchData();
    },
    openLastSavedItemInFolder() {
      const lastItemOfFolder = this.folders[this.folders.length - 1];
      const lastItemId = lastItemOfFolder.id;
      this.$router.push({
        name: 'folder_conversations',
        params: { id: lastItemId },
      });
    },
    openLastItemAfterDeleteInFolder() {
      if (this.folders.length > 0) {
        this.openLastSavedItemInFolder();
      } else {
        this.$router.push({ name: 'home' });
        this.fetchConversations();
      }
    },
    isConversationSelected(id) {
      return this.selectedConversations.includes(id);
    },
    selectConversation(conversationId, inboxId) {
      this.selectedConversations.push(conversationId);
      this.selectedInboxes.push(inboxId);
    },
    deSelectConversation(conversationId, inboxId) {
      this.selectedConversations = this.selectedConversations.filter(
        item => item !== conversationId
      );
      this.selectedInboxes = this.selectedInboxes.filter(
        item => item !== inboxId
      );
    },
    selectAllConversations(check) {
      if (check) {
        this.selectedConversations = this.conversationList.map(item => item.id);
        this.selectedInboxes = this.conversationList.map(item => item.inbox_id);
      } else {
        this.resetBulkActions();
      }
    },
    clearPaginationIntervals() {
      this.paginationIntervals.forEach(clearInterval);
      this.paginationIntervals = [];
    },
    async onAssignAgent(agent) {
      try {
        await this.$store.dispatch('bulkActions/process', {
          type: 'Conversation',
          ids: this.selectedConversations,
          fields: {
            assignee_id: agent.id,
          },
        });
        this.selectedConversations = [];
        this.fetchConversations();
        this.showAlert(this.$t('BULK_ACTION.ASSIGN_SUCCESSFUL'), {
          status: wootConstants.SNACKBAR_TYPE.SUCCESS,
        });
      } catch (err) {
        this.showAlert(this.$t('BULK_ACTION.ASSIGN_FAILED'), {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
      }
    },
    async onAssignInbox(inbox) {
      try {
        await this.$store.dispatch('bulkActions/process', {
          type: 'Conversation',
          ids: this.selectedConversations,
          fields: {
            inbox_id: inbox.id,
          },
        });
        this.updateInboxOnConversations(this.selectedConversations, inbox.id);
        this.selectedConversations = [];
        this.$store.dispatch('conversationStats/get', this.conversationFilters);
        this.showAlert(this.$t('BULK_ACTION.INBOX.ASSIGN_SUCCESSFUL'), {
          status: wootConstants.SNACKBAR_TYPE.SUCCESS,
        });
      } catch (err) {
        this.showAlert(this.$t('BULK_ACTION.INBOX.ASSIGN_FAILED'), {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
      }
    },
    async onAssignLabels(labels) {
      try {
        await this.$store.dispatch('bulkActions/process', {
          type: 'Conversation',
          ids: this.selectedConversations,
          labels: {
            add: labels,
          },
        });
        this.selectedConversations = [];
        this.$store.dispatch('conversationStats/get', this.conversationFilters);
        this.showAlert(this.$t('BULK_ACTION.LABELS.ASSIGN_SUCCESSFUL'), {
          status: wootConstants.SNACKBAR_TYPE.SUCCESS,
        });
      } catch (err) {
        this.showAlert(this.$t('BULK_ACTION.LABELS.ASSIGN_FAILED'), {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
      }
    },
    async onUpdateConversations(status) {
      try {
        this.selectedConversations.forEach(conversationId => {
          if (
            status === wootConstants.STATUS_TYPE.RESOLVED ||
            status === wootConstants.STATUS_TYPE.ABANDONED ||
            status === wootConstants.STATUS_TYPE.DELETED
          ) {
            this.$store.dispatch('draftStore/delete', {
              id: conversationId,
              replyType: REPLY_EDITOR_MODES.REPLY,
            });

            this.$store.dispatch('draftStore/delete', {
              id: conversationId,
              replyType: REPLY_EDITOR_MODES.REPLY_BOT,
            });
          }

          if (status === wootConstants.STATUS_TYPE.DELETED) {
            this.$store.dispatch('draftStore/delete', {
              id: conversationId,
              replyType: REPLY_EDITOR_MODES.NOTE,
            });
          }
        });

        await this.$store.dispatch('bulkActions/process', {
          type: 'Conversation',
          ids: this.selectedConversations,
          fields: {
            status,
          },
        });
        this.selectedConversations = [];
        this.$store.dispatch('conversationStats/get', this.conversationFilters);
        this.showAlert(this.$t('BULK_ACTION.UPDATE.UPDATE_SUCCESSFUL'), {
          status: wootConstants.SNACKBAR_TYPE.SUCCESS,
        });
      } catch (err) {
        this.showAlert(this.$t('BULK_ACTION.UPDATE.UPDATE_FAILED'), {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
      }
    },
    allSelectedConversationsStatus(status) {
      if (!this.selectedConversations.length) return false;
      return this.selectedConversations.every(item => {
        return this.$store.getters.getConversationById(item).status === status;
      });
    },
    conversationsContainStatus(status) {
      if (!this.selectedConversations.length) return false;
      return this.selectedConversations.some(item => {
        return this.$store.getters.getConversationById(item).status === status;
      });
    },
    fillTrackedConversations() {
      if (!this.enableNotificationPolling) {
        return;
      }

      this.trackedConversations = this.conversationList
        .slice(0, 20)
        .map(conversation => ({
          id: conversation.id,
          unreadCount: conversation.messages.filter(
            message =>
              message.created_at * 1000 >
                conversation.agent_last_seen_at * 1000 &&
              message.message_type === MESSAGE_TYPE.INCOMING &&
              message.private !== true
          ).length,
        }));
    },
    triggerAudioNotifications() {
      if (!this.enableNotificationPolling) {
        return;
      }

      let updatedTrackedConversations = [];

      this.conversationList.slice(0, 20).forEach(conversation => {
        const unreadMessages = conversation.messages.filter(message => {
          return (
            message.created_at * 1000 >
              conversation.agent_last_seen_at * 1000 &&
            message.message_type === MESSAGE_TYPE.INCOMING &&
            message.private !== true
          );
        });

        if (unreadMessages.length > 0) {
          const firstUnreadMessage = unreadMessages[0];
          const previousTrackedConversation = this.trackedConversations.find(
            tracked => tracked.id === conversation.id
          );

          if (
            !previousTrackedConversation ||
            previousTrackedConversation.unreadCount !== unreadMessages.length
          ) {
            newMessageNotification(firstUnreadMessage);
          }

          updatedTrackedConversations.push({
            id: conversation.id,
            unreadCount: unreadMessages.length,
          });
        }
      });

      this.trackedConversations = updatedTrackedConversations;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'dashboard/assets/scss/woot';

.spinner {
  margin-top: var(--space-normal);
  margin-bottom: var(--space-normal);
}

.conversations-list-wrap {
  flex-shrink: 0;
  width: 34rem;
  overflow: hidden;
  @include breakpoint(large up) {
    width: 36rem;
  }
  @include breakpoint(xlarge up) {
    width: 35rem;
  }
  @include breakpoint(xxlarge up) {
    width: 38rem;
  }
  @include breakpoint(xxxlarge up) {
    flex-basis: 46rem;
  }
}

.filter--actions {
  display: flex;
  align-items: center;
}

.btn-filter {
  margin: 0 var(--space-smaller);
}

.filter__applied {
  padding: 0 0 var(--space-slab) 0 !important;
  border-bottom: 1px solid var(--color-border);
}

.delete-custom-view__button {
  margin-right: var(--space-normal);
}
</style>
